import { useEffect, useState } from 'react';
import api from '../Services/api';
import { Categoria, EstadoCivil, Genero, GiftCard, Localidad, Pais, Provincia } from '../Interfaces/formInterfaces';

export const useGeneros = () => {
  const [generos, setGeneros] = useState<Genero[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGeneros = async () => {
      try {
        const response = await api.get('/api/config/generos');
        setGeneros(response.data);
      } catch (error) {
        console.error('Error fetching generos:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchGeneros();
  }, []);

  return { generos, loading };
};

export const useEstadosCiviles = () => {
  const [estadosCiviles, setEstadosCiviles] = useState<EstadoCivil[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEstadosCiviles = async () => {
      try {
        const response = await api.get('/api/config/estados-civiles');
        setEstadosCiviles(response.data);
      } catch (error) {
        console.error('Error fetching estados civiles:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchEstadosCiviles();
  }, []);

  return { estadosCiviles, loading };
};

export const usePaises = () => {
  const [paises, setPaises] = useState<Pais[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPaises = async () => {
      try {
        const response = await api.get('/api/config/paises');
        setPaises(response.data);
      } catch (error) {
        console.error('Error fetching países:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPaises();
  }, []);

  return { paises, loading };
};

export const useProvincias = (paisId: string | null) => {
  const [provincias, setProvincias] = useState<Provincia[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (paisId) {
      const fetchProvincias = async () => {
        try {
          const response = await api.get(`/api/config/paises/${paisId}/provincias`);
          setProvincias(response.data);
        } catch (error) {
          console.error('Error fetching provincias:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchProvincias();
    } else {
      setProvincias([]);
      setLoading(false);
    }
  }, [paisId]);

  return { provincias, loading };
};

export const useLocalidades = (provinciaId: string | null) => {
  const [localidades, setLocalidades] = useState<Localidad[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (provinciaId) {
      const fetchLocalidades = async () => {
        try {
          const response = await api.get(`/api/config/provincias/${provinciaId}/localidades`);
          setLocalidades(response.data);
        } catch (error) {
          console.error('Error fetching localidades:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchLocalidades();
    } else {
      setLocalidades([]);
      setLoading(false);
    }
  }, [provinciaId]);

  return { localidades, loading };
};

export const useGiftCards = () => {
  const [giftCards, setGiftCards] = useState<GiftCard[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGiftCards = async () => {
      try {
        const response = await api.get('/api/giftcard');
        setGiftCards(response.data.giftCards);
      } catch (error) {
        console.error('Error fetching gift cards:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchGiftCards();
  }, []);

  return { giftCards, loading };
};

export const useCategorias = () => {
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await api.get('/api/categoria');
        setCategorias(response.data);
      } catch (error) {
        console.error('Error fetching categorías:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCategorias();
  }, []);

  return { categorias, loading };
};

