import React, { ReactNode } from 'react';
import Navbar from '../Navbar/Navbar';
import {LayoutContainer, Content} from './style';
import Footer from '../Footer/Footer';


interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <LayoutContainer>
      <Navbar />
      <Content>{children}</Content>
      <Footer></Footer>
    </LayoutContainer>
  );
};

export default MainLayout;
