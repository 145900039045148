import styled from 'styled-components';

export const LoginRegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
`;

export const Title = styled.h2`
  font-size: 24px;
  color: #1a237e;
  margin-bottom: 30px;
  font-weight: bold;
`;

export const OptionButton = styled.div`
  background-color: #1a237e;
  color: white;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background 0.3s;
  width: 200px;
  text-align: center;

  a {
    color: white;
    text-decoration: none;
  }

  &:hover {
    background-color: #b2dfdb;
  }
`;

export const Divider = styled.hr`
  width: 60%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
`;

export const OptionLink = styled.div`
  font-size: 16px;
  color: #1a237e;
  cursor: pointer;
  font-weight: bold;

  a {
    color: #1a237e;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
`;
