import styled from 'styled-components';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button';

export const StyledButton = styled(Button)<ButtonProps & { customSize: string }>`
  width: ${({ customSize }) => customSize || '150px'};
  height: 40px;
  font-size: 14px;
  background-color: #003366;
  color: white;
  text-transform: none;
  &:hover {
    background-color: #002244;
  }
`;
