import React from 'react';
import { Container, Button, Typography, Box } from '@mui/material';
import MainLayout from '../Layout/MainLayout';
import { Title } from './style';

const Account: React.FC = () => {
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.replace('/login');
  };

  return (
    <MainLayout>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Title>Mi Cuenta</Title>
          <Typography variant="body1" gutterBottom>
            Bienvenido a tu cuenta.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogout}
            sx={{ mt: 4 }}
          >
            Cerrar Sesión
          </Button>
        </Box>
      </Container>
    </MainLayout>
  );
};

export default Account;
