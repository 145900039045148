import React from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalImageContainer,
  ModalImage,
  ModalFooter,
  ModalInfoContainer,
  ModalTitle,
  ModalCategory,
  ModalPrice,
  ModalDescription,
  ModalConditions,
  ModalButton,
} from './styleModal';
import { GiftCard } from '../../Interfaces/formInterfaces';

interface GiftCardModalProps {
  giftCard: GiftCard;
  onClose: () => void;
}

const GiftCardModal: React.FC<GiftCardModalProps> = ({ giftCard, onClose }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalImageContainer>
          <ModalImage src={giftCard.imagenUrl} alt={giftCard.nombre} />
          <ModalFooter>
            <ModalButton>Favoritos</ModalButton>
            <ModalButton primary>Agregar al carrito</ModalButton>
            <ModalButton onClick={onClose}>Cerrar</ModalButton>
          </ModalFooter>
        </ModalImageContainer>

        <ModalInfoContainer>
          <ModalTitle>{giftCard.nombre}</ModalTitle>
          <ModalCategory>Categoría: {giftCard.categoria}</ModalCategory>
          <ModalPrice>Precio: ${giftCard.valor}</ModalPrice>
          <ModalDescription>
            <strong>Descripción:</strong> {giftCard.descripcion}
          </ModalDescription>
          <ModalConditions>
            <strong>Condiciones:</strong> {giftCard.condiciones}
          </ModalConditions>
        </ModalInfoContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default GiftCardModal;
