import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaCreditCard, FaHome, FaUserCircle, FaShoppingCart, FaInfoCircle, FaPhoneAlt, FaSearch } from 'react-icons/fa';
import {
  NavbarContainer,
  Logo,
  NavLinks,
  MenuItem,
  IconWrapper,
  IconContainer,
  ActionIcon,
  SearchContainer,
  SearchInput,
  SearchIconWrapper,
} from './style';
import useAuth from '../../Hooks/useAuth';

const Navbar: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleUserIconClick = () => {
    if (isAuthenticated) {
      navigate('/account');
    } else {
      navigate('/account-options');
    }
  };

  return (
    <NavbarContainer>
      <Link to={'/'}>
        <Logo src="/img/giftwave_logo.png" alt="GiftWave Logo" />
      </Link>

      <SearchContainer>
        <SearchInput placeholder="Buscar gift cards..." />
        <SearchIconWrapper>
          <FaSearch />
        </SearchIconWrapper>
      </SearchContainer>

      <NavLinks>
        <MenuItem>
          <Link to="/">
            <IconWrapper>
              <FaHome />
              <span>Inicio</span>
            </IconWrapper>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/about">
            <IconWrapper>
              <FaInfoCircle />
              <span>Acerca</span>
            </IconWrapper>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/giftCards">
            <IconWrapper>
              <FaCreditCard />
              <span>Gift Cards</span>
            </IconWrapper>
          </Link>
        </MenuItem>
        {isAuthenticated && (
          <MenuItem>
            <Link to="/account">
              <IconWrapper>
                <FaUserCircle />
                <span>Mi cuenta</span>
              </IconWrapper>
            </Link>
          </MenuItem>
        )}
        <MenuItem>
          <Link to="/contact">
            <IconWrapper>
              <FaPhoneAlt />
              <span>Contacto</span>
            </IconWrapper>
          </Link>
        </MenuItem>
      </NavLinks>

      <IconContainer>
        {!isAuthenticated && (
          <ActionIcon onClick={handleUserIconClick}>
            <FaUserCircle />
          </ActionIcon>
        )}
        <ActionIcon onClick={() => window.location.href = "/cart"}>
          <FaShoppingCart />
        </ActionIcon>
      </IconContainer>
    </NavbarContainer>
  );
};

export default Navbar;
