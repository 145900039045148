import { useState } from 'react';

const useForm = <T extends object>(initialState: T) => {
    const [formData, setFormData] = useState(initialState);

    const updateFormData = (field: keyof T | string, value: any) => {
        setFormData((prevData) => {
            if (typeof field === 'string' && field.includes('.')) {
                const [parentKey, childKey] = field.split('.');
                if (parentKey in prevData && typeof prevData[parentKey as keyof T] === 'object') {
                    return {
                        ...prevData,
                        [parentKey]: {
                            ...(prevData[parentKey as keyof T] as object),
                            [childKey]: value,
                        },
                    };
                }
            }
            return {
                ...prevData,
                [field]: value,
            };
        });
    };

    return { formData, updateFormData };
};

export default useForm;
