import styled from 'styled-components';

// Wrapper del ícono de búsqueda (lupa)
export const SearchIconWrapper = styled.div`
  cursor: pointer;
  font-size: 1.8rem;
  color: #0288D1;
  position: absolute;
  right: -1.5rem; // Para mover la lupa fuera del borde del contenedor
  transform: translateX(50%); // Hacer que parezca que sale del borde
`;

// Contenedor de búsqueda
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background: #e3f2fd;
  border-radius: 16px;
  padding: 0.3rem 3rem;
  margin: 0 1.5rem;
  position: relative; 
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  padding: 0.5rem;
  width: 200px;
  background: transparent;
  font-size: 1rem;
`;

// Ícono del buscador
export const SearchIcon = styled.div`
  cursor: pointer;
  font-size: 1.5rem;
  color: #009688;
  margin-left: 0.5rem;
`;

// Contenedor principal de la barra de navegación
export const NavbarContainer = styled.nav`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Logo del navbar
export const Logo = styled.img`
  width: 60px;
  cursor: pointer;
`;

// Contenedor de los enlaces del menú
export const NavLinks = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 4rem;
  position: relative;
  padding: 1.5rem 3rem;  // Hacer la caja más grande
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;  // Borde más redondo
`;


// Estilo para cada elemento del menú (con ícono y texto)
export const MenuItem = styled.div`
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    transform: translateY(-5px);
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
  }

  &::after {
    content: '';
    position: absolute;
    right: -1.5rem; 
    top: 50%;
    transform: translateY(-50%);
    height: 60%;  
    width: 2px;
    background-color: rgba(0, 150, 136, 0.3); 
  }

  &:last-child::after {
    display: none; 
  }
`;

// Wrapper del ícono y el texto en el menú
export const IconWrapper = styled.div`
  font-size: 2rem;
  color: #009688;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;

  span {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 0.5rem;
    color: #444;
  }
`;

// Contenedor de íconos de usuario y carrito
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

// Ícono de usuario y carrito actualizado
export const ActionIcon = styled.div`
  font-size: 2rem;
  color: #536DFE;
  cursor: pointer;
  transition: transform 0.3s;
  margin-right: 0.5rem;

  &:hover {
    transform: scale(1.1);
  }
`;
