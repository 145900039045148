import React from 'react';
import TextField from '@mui/material/TextField';

interface NumberInputProps {
  label: string;
  value: number | string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  error?: string;
}

const NumberInput: React.FC<NumberInputProps> = ({ label, value, onChange, required = false, error  = ''  }) => {
  return (
    <TextField
      type="number"
      label={label}
      value={value}
      onChange={onChange}
      required={required}
      variant="outlined"
      error={Boolean(error)} 
      helperText={error} 
      fullWidth
    />
  );
};

export default NumberInput;
