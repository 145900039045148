import styled from 'styled-components';

// Capa de superposição do modal
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

// Contenedor do conteúdo do modal
export const ModalContent = styled.div`
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  display: flex;
  padding: 2rem;
  overflow: hidden;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

// Contenedor da imagem e dos botões
export const ModalImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-right: 2rem;
`;

// Imagem no modal
export const ModalImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 1rem;
`;

// Contenedor do rodapé com os botões de ação embaixo da imagem
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
`;

// Contenedor da informação do modal
export const ModalInfoContainer = styled.div`
  width: 60%;
  text-align: left;
`;

// Título do modal
export const ModalTitle = styled.h2`
  font-size: 2rem;
  color: #ff7e5f;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
`;

// Categoria da tarjeta no modal
export const ModalCategory = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
`;

// Preço no modal
export const ModalPrice = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #28a745;
  margin-bottom: 1rem;
`;

// Descrição da tarjeta de presente no modal (com scroll apenas se necessário)
export const ModalDescription = styled.div`
  font-size: 1rem;
  color: #555;
  overflow-y: auto; // Scroll se necessário
  max-height: 150px; // Altura máxima antes de mostrar o scroll
  margin-bottom: 1rem;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
`;

// Condições da tarjeta de presente no modal (com scroll apenas se necessário)
export const ModalConditions = styled.div`
  font-size: 1rem;
  color: #555;
  font-style: italic;
  overflow-y: auto; // Scroll se necessário
  max-height: 150px; // Altura máxima antes de mostrar o scroll

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
`;

// Botões de ação do modal (Favoritos, Adicionar ao carrinho, Fechar)
export const ModalButton = styled.button<{ primary?: boolean }>`
  background: ${({ primary }) => (primary ? 'linear-gradient(135deg, #ff7e5f, #feb47b)' : '#f0f0f0')};
  color: ${({ primary }) => (primary ? '#ffffff' : '#333')};
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: ${({ primary }) => (primary ? '#e3675a' : '#e0e0e0')};
  }
`;
