import { createBrowserRouter } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Register from './Pages/Register/Register';
import LoginRegister from './Pages/LoginRegister/LoginRegister';
import Login from './Pages/Login/Login';
import Account from './Pages/Account/Account';
import ProtectedRoute from './Components/ProtectedRoute';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import GiftCards from './Pages/GiftCard/GiftCards';

const router = createBrowserRouter(
  [
    { path: "/", element: <Home /> },
    { path: "/register", element: <ProtectedRoute unauthenticatedOnly><Register /></ProtectedRoute> },
    { path: "/account-options", element: <ProtectedRoute unauthenticatedOnly><LoginRegister /></ProtectedRoute> },
    { path: "/login", element: <ProtectedRoute unauthenticatedOnly><Login /></ProtectedRoute> },
    { path: "/account", element: <ProtectedRoute><Account /></ProtectedRoute> },
    { path: "/about", element: <About />},
    { path: "/contact", element: <Contact />},
    { path: "/giftCards", element: <GiftCards />},



  ],
  {
    future: {
      v7_relativeSplatPath: true,
    },
  }
);

export default router;
