import React from 'react';
import { Container, Grid, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FooterContainer, FooterSection, FooterTitle, FooterLink, FooterCopyright, FooterIconContainer, FooterLogoWrapper, FooterLogo } from './style';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <FooterSection>
              <FooterTitle variant="h6" gutterBottom>GiftWave</FooterTitle>
              <FooterLink href="/about">Sobre Nosotros</FooterLink>
              <FooterLink href="/contact">Contacto</FooterLink>
              <FooterLink href="/help">Ayuda</FooterLink>
            </FooterSection>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FooterSection>
              <FooterTitle variant="h6" gutterBottom>Contacto</FooterTitle>
              <div>
                <MailOutlineIcon fontSize="small" style={{ marginRight: '0.5rem' }} />
                <span>contacto@giftwave.com</span>
              </div>
              <div>
                <PhoneIcon fontSize="small" style={{ marginRight: '0.5rem' }} />
                <span>+54 11 1234 5678</span>
              </div>
              <div>
                <LocationOnIcon fontSize="small" style={{ marginRight: '0.5rem' }} />
                <span>Buenos Aires, Argentina</span>
              </div>
            </FooterSection>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FooterSection>
              <FooterTitle variant="h6" gutterBottom>Síguenos</FooterTitle>
              <FooterIconContainer>
                <IconButton href="https://facebook.com" target="_blank" style={{ color: '#ffffff' }}>
                  <FacebookIcon />
                </IconButton>
                <IconButton href="https://instagram.com" target="_blank" style={{ color: '#ffffff' }}>
                  <InstagramIcon />
                </IconButton>
                <IconButton href="https://twitter.com" target="_blank" style={{ color: '#ffffff' }}>
                  <TwitterIcon />
                </IconButton>
              </FooterIconContainer>
            </FooterSection>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FooterLogoWrapper>
              <Link to={'/'}> <FooterLogo src="/img/giftwave_logo.png" alt="GiftWave Logo" /></Link>
            </FooterLogoWrapper>
          </Grid>
        </Grid>
        <FooterCopyright variant="body2">
          © 2024 GiftWave. Todos los derechos reservados.
        </FooterCopyright>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
