import React from 'react';
import TextField from '@mui/material/TextField';

interface TextInputProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  error?: string; 
}

const TextInput: React.FC<TextInputProps> = ({ label, value, onChange, required = false, error = '' }) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      required={required}
      variant="outlined"
      fullWidth
      error={Boolean(error)} 
      helperText={error} 
    />
  );
};

export default TextInput;
