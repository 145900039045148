import styled from 'styled-components';

export const ModalBox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: #ffffff;
    border: 2px solid #003366; // Azul oscuro
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 32px;
    text-align: center;
    border-radius: 8px;
`;

export const ModalTitle = styled.h2`
    font-size: 20px;
    color: #003366; // Azul oscuro
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif; // Fuente Roboto
`;

export const ModalDescription = styled.p`
    font-size: 16px;
    color: #333333; // Gris oscuro
    margin-bottom: 24px;
    font-family: 'Roboto', sans-serif; // Fuente Roboto
`;

export const ModalButton = styled.button`
    background: #003366; // Azul oscuro
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
    &:hover {
        background: #002244; // Azul más oscuro
    }
    font-family: 'Roboto', sans-serif; // Fuente Roboto
`;

export const ProfileImagePreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 10px;
    background: #f1f1f1;
`;

export const ZoomSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    input[type="range"] {
        width: 80px;
        margin-top: 5px;
    }
`;

export const ProfileImagePreview = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover; 
    transform: scale(1); 
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.3);
    }
`;

export const ErrorContainer = styled.div`
    position: absolute;
    left: 0;
    margin-left: 30px;
    // Otros estilos que necesites
`;


export const RegisterContainer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0faff; 
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  gap: 10px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Tab = styled.button<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? '#003366' : '#d0e7ff')}; 
  color: ${({ isActive }) => (isActive ? 'white' : '#003366')}; 
  padding: 8px 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  transition: background 0.3s;
  &:hover {
    background: #002244; 
    color: white;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const SectionTitle = styled.h2`
  font-size: 22px;
  text-align: center;
  margin-bottom: 10px;
  color: #003366; 
  font-family: 'Roboto', sans-serif; 
`;

export const Button = styled.button<{ loading?: boolean }>`
  background: ${({ loading }) => (loading ? '#999' : '#003366')};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 20px;
  transition: background 0.3s;
  &:hover {
    background: ${({ loading }) => (loading ? '#999' : '#00838f')}; 
  }
  font-family: 'Roboto', sans-serif;
`;

export const LinkText = styled.p`
  font-size: 12px;
  color: #003366; /* Azul oscuro */
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  text-align: center;
  font-family: 'Roboto', sans-serif; /* Fuente Roboto */
`;

export const ProfileImageInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif; /* Fuente Roboto */
  label {
    color: #003366;
    font-weight: bold;
    margin-bottom: 5px;
  }
`;
