import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../Hooks/useAuth';

interface ProtectedRouteProps {
  children: React.ReactElement;
  unauthenticatedOnly?: boolean; 
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, unauthenticatedOnly = false }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Cargando...</div>; 
  }

  if (isAuthenticated && unauthenticatedOnly) {
    return <Navigate to="/account" replace />;
  }

  if (!isAuthenticated && !unauthenticatedOnly) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
