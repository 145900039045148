import React from 'react';
import { TextField } from '@mui/material';

interface EmailInputProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  error?: string;
}

const EmailInput: React.FC<EmailInputProps> = ({ label, value, onChange, required = false, error  = '' }) => {
  return (
    <TextField
      label={label}
      type="email"
      value={value}
      onChange={onChange}
      required={required}
      fullWidth
      variant="outlined"
      error={Boolean(error)} 
      helperText={error} 
    />
  );
};

export default EmailInput;
