import React from 'react';
import { Link } from 'react-router-dom';
import { LoginRegisterContainer, Title, OptionButton, OptionLink, Divider } from './style';
import MainLayout from '../Layout/MainLayout';

const LoginRegister: React.FC = () => {
    return (
        <MainLayout>
            <LoginRegisterContainer>
                <Title>Iniciá sesión acá</Title>
                <OptionButton>
                    <Link to="/login">Iniciar sesión</Link>
                </OptionButton>
                <Divider />
                <OptionLink>
                    <Link to="/register">Crear cuenta</Link>
                </OptionLink>
            </LoginRegisterContainer>
        </MainLayout>

    );
};

export default LoginRegister;
