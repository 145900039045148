import { styled } from '@mui/material/styles';
import { Box, Typography, Link } from '@mui/material';

export const FooterContainer = styled(Box)({
  backgroundColor: '#00BCD4',
  color: '#ffffff',
  padding: '1.5rem 0',
  textAlign: 'left',
  position: 'relative',
  marginTop: '2rem',
});

export const FooterSection = styled(Box)({
  marginBottom: '1rem',
  '& p': {
    margin: 0,
  },
  '& div': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
});

export const FooterTitle = styled(Typography)({
  fontSize: '1.1rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
});

export const FooterLink = styled(Link)({
  color: '#ffffff',
  textDecoration: 'none',
  fontSize: '0.95rem',
  marginBottom: '0.5rem',
  display: 'block',

  '&:hover': {
    textDecoration: 'underline',
  },
});

export const FooterCopyright = styled(Typography)({
  textAlign: 'center',
  marginTop: '1.5rem',
  fontSize: '0.8rem',
  color: '#e0f7fa',
});

export const FooterIconContainer = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
  marginTop: '0.5rem',
});

export const FooterLogoWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '100%',
});

export const FooterLogo = styled('img')({
  width: '120px',
  height: 'auto',
  filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2))',
});
