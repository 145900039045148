import styled from 'styled-components';
import backgroundImage from '../../assets/img/background.png'; 

export const LayoutContainer = styled.div`
  position: relative;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8); 
    z-index: -1;
  }
`;

export const Content = styled.main`
  flex: 1;
`;