import React from 'react';
import TextField from '@mui/material/TextField';

interface DateInputProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const DateInput: React.FC<DateInputProps> = ({ label, value, onChange, required = false }) => {
  return (
    <TextField
      type="date"
      label={label}
      value={value}
      onChange={onChange}
      required={required}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      fullWidth
    />
  );
};

export default DateInput;
