import styled, { keyframes } from 'styled-components';

// Animaciones previas
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const FeaturedContainer = styled.div`
  background: #b3e5fc;
  padding: 3rem;
  border-radius: 16px;
  margin: 2rem 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;

  h3 {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 2rem;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const CardContainer = styled.div`
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 16px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: calc(33% - 2rem);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  animation: ${fadeIn} 1.5s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    animation: ${float} 2s ease-in-out infinite; 
  }

  .card-image {
    height: 100px;
    overflow: hidden;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover; /* Asegura que la imagen se adapte */
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
`;

export const CardTitle = styled.h4`
  font-size: 1.5rem;
  color: #003366;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const CardDescription = styled.p`
  font-size: 1rem;
  color: #004d80;
  margin-bottom: 0.5rem;
`;



export const TestimonialSection = styled.div`
  background: #e3f2fd;
  padding: 4rem 2rem;
  margin: 2rem 0;
  text-align: center;
  border-radius: 16px;
  animation: ${fadeIn} 2s ease-in-out;

  h3 {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 2rem;
  }
`;

export const TestimonialContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

export const TestimonialCard = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  animation: ${fadeIn} 1.5s ease-in-out;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

export const TestimonialQuote = styled.blockquote`
  font-style: italic;
  font-size: 1.1rem;
  color: #003366;
  margin-bottom: 1.5rem;
`;

export const ClientName = styled.cite`
  display: block;
  color: #006699;
  font-size: 1rem;
  font-weight: bold;
`;

export const HomeContainer = styled.div`
  padding: 2rem;
  text-align: center;
  color: #003366;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

export const Banner = styled.div`
  background: #0099cc;
  color: white;
  padding: 3rem;
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  img {
    width: 200px;
    height: auto;
    margin-right: 20px;
    align-self: flex-start; /* Alinear logo a la izquierda */
  }

  div {
    flex-grow: 1;
    text-align: center; /* Centralizar el texto dentro del cuadro */
  }
`;

export const Title = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 1rem;
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  color: #f0f8ff;
  margin-bottom: 1.5rem;
`;

export const Button = styled.button`
  background: #006699;
  color: #ffffff;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;

  &:hover {
    background: #004d80;
    transform: scale(1.05);
  }
`;

export const Section = styled.div`
  margin: 2rem 0;

  h3 {
    font-size: 2rem;
    color: #003366;
    margin-bottom: 1rem;
  }
`;

export const Description = styled.p`
  font-size: 1.1rem;
  color: #004d80;
  margin-bottom: 2rem;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;

  div {
    text-align: center;

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 0.5rem;
      transition: transform 0.3s;

      &:hover {
        animation: ${float} 2s ease-in-out infinite; /* Movimiento solo al hacer hover */
      }
    }

    p {
      font-size: 1rem;
      color: #003366;
    }
  }
`;

export const Testimonial = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-top: 3rem;
  animation: ${fadeIn} 2s ease-in-out;

  blockquote {
    font-style: italic;
    font-size: 1.2rem;
    color: #003366;
  }

  cite {
    display: block;
    margin-top: 1rem;
    color: #006699;
    font-size: 1rem;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  svg {
    color: #ffb400; /* Icono de estrellas en color dorado */
  }
`;