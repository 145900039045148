export const validateFieldsForTab = (fields: any, activeTab: number) => {
    const errors: { [key: string]: string } = {};

    if (activeTab === 1) {
        // Validaciones de la pestaña 1: Datos Personales
        if (!fields.nombre) {
            errors['nombre'] = "Nombre es obligatorio";
        } else {
            if (!fields.nombre.match(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/)) {
                errors['nombre'] = "Nombre solo puede contener letras y espacios";
            }
            if (fields.nombre.length > 50) {
                errors['nombre'] = "Nombre no puede tener más de 50 caracteres";
            }
        }

        if (!fields.apellido) {
            errors['apellido'] = "Apellido es obligatorio";
        } else {
            if (!fields.apellido.match(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/)) {
                errors['apellido'] = "Apellido solo puede contener letras y espacios";
            }
            if (fields.apellido.length > 50) {
                errors['apellido'] = "Apellido no puede tener más de 50 caracteres";
            }
        }

        if (!fields.dni) {
            errors['dni'] = "DNI es obligatorio";
        } else {
            if (fields.dni.length < 7 || fields.dni.length > 8 || !fields.dni.match(/^\d+$/)) {
                errors['dni'] = "DNI debe ser un número de 7 u 8 dígitos";
            }
        }

        if (!fields.cuil) {
            errors['cuil'] = "CUIL es obligatorio";
        } else {
            if (!fields.cuil.match(/^[0-9.-]+$/)) {
                errors['cuil'] = "CUIL solo puede contener números, puntos y guiones";
            }
        }
    }

    if (activeTab === 2) {
        // Validaciones de la pestaña 2: Dirección
        if (!fields.direccion.calle) {
            errors['direccion.calle'] = "Calle es obligatoria";
        } else if (fields.direccion.calle.length > 100) {
            errors['direccion.calle'] = "Calle no debe exceder 100 caracteres";
        }

        if (!fields.direccion.numero) {
            errors['direccion.numero'] = "Número es obligatorio";
        } else if (!fields.direccion.numero.match(/^\d{1,6}$/)) {
            errors['direccion.numero'] = "Número debe tener entre 1 y 6 dígitos";
        }

        if (fields.direccion.piso && !fields.direccion.piso.match(/^\d{1,6}$/)) {
            errors['direccion.piso'] = "Piso debe ser un valor numérico";
        }

        if (!fields.direccion.codigoPostal) {
            errors['direccion.codigoPostal'] = "Código Postal es obligatorio";
        } else if (!fields.direccion.codigoPostal.match(/^\d{4}$/)) {
            errors['direccion.codigoPostal'] = "Código Postal debe ser un número de 4 dígitos";
        }

        if (!fields.direccion.localidadId) {
            errors['direccion.localidadId'] = "Localidad es obligatoria";
        }

        if (!fields.direccion.provinciaId) {
            errors['direccion.provinciaId'] = "Provincia es obligatoria";
        }

        if (!fields.direccion.paisId) {
            errors['direccion.paisId'] = "País es obligatorio";
        }
    }

    if (activeTab === 3) {
        // Validaciones de la pestaña 3: Contacto
        if (!fields.contacto.codigoPais) {
            errors['contacto.codigoPais'] = "Código País es obligatorio";
        } else if (!fields.contacto.codigoPais.match(/^\+?\d{1,4}$/)) {
            errors['contacto.codigoPais'] = "Código País debe tener entre 1 y 4 dígitos";
        }

        if (!fields.contacto.codigoArea) {
            errors['contacto.codigoArea'] = "Código Área es obligatorio";
        } else if (!fields.contacto.codigoArea.match(/^\d{1,4}$/)) {
            errors['contacto.codigoArea'] = "Código Área debe tener entre 1 y 4 dígitos";
        }

        if (!fields.contacto.numero) {
            errors['contacto.numero'] = "Número de Teléfono es obligatorio";
        } else if (!fields.contacto.numero.match(/^\d{6,12}$/)) {
            errors['contacto.numero'] = "Número debe tener entre 6 y 12 dígitos";
        }
    }

    if (activeTab === 4) {
        // Validaciones de la pestaña 4: Datos de Login
        if (!fields.email) {
            errors['email'] = "Correo electrónico es obligatorio";
        } else {
            if (!fields.email.match(/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/)) {
                errors['email'] = "Correo electrónico no válido";
            }
        }

        if (!fields.contraseña) {
            errors['contraseña'] = "Contraseña es obligatoria";
        } else {
            if (fields.contraseña.length < 8 || !fields.contraseña.match(/[A-Z]/) || !fields.contraseña.match(/[a-z]/) || !fields.contraseña.match(/[!@#$%^&*(),.?":{}|<>]/)) {
                errors['contraseña'] = "La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, una minúscula y un carácter especial";
            }
        }
    }

    return errors;
};
