import React from 'react';
import {
    HomeContainer,
    Banner,
    Title,
    Subtitle,
    Description,
    FeaturedContainer,
    CardContainer,
    CardTitle,
    CardDescription,
    Button,
    IconContainer,
    Section,
    TestimonialCard,
    TestimonialContainer,
    TestimonialQuote,
    TestimonialSection,
    CardsWrapper,
    RatingContainer,
    ClientName
} from './style';
import MainLayout from '../Layout/MainLayout';
import { Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

const Home: React.FC = () => {
    return (
        <MainLayout>
            <HomeContainer>
                <Banner>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src="/img/giftwave_logo.png"
                            alt="GiftWave Logo"
                            style={{ width: '200px', height: 'auto', marginRight: '20px' }}
                        />
                        <div>
                            <Title>Descubre el mundo de GiftWave</Title>
                            <Subtitle>Las mejores gift cards para todos los gustos</Subtitle>
                            <Button>Explorar Gift Cards</Button>
                        </div>
                    </div>
                </Banner>

                <Section>
                    <h3>¿Quiénes somos?</h3>
                    <Description>
                        En GiftWave, nos dedicamos a ofrecer una amplia variedad de tarjetas de regalo para todas las ocasiones. Encuentra desde tarjetas de marcas reconocidas hasta experiencias inolvidables para compartir con quienes más quieres.
                    </Description>
                </Section>

                <FeaturedContainer>
                    <h3>Gift Cards Destacadas</h3>
                    <CardsWrapper>
                        <CardContainer>
                            <div className="card-image">
                                <img src="/img/BurguerKingGift.png" alt="Burger King" />
                            </div>
                            <CardTitle>Burger King</CardTitle>
                            <CardDescription>Disfruta de deliciosas hamburguesas y más.</CardDescription>
                        </CardContainer>
                        <CardContainer>
                            <div className="card-image">
                                <img src="/img/AdidasGift.png" alt="Adidas" />
                            </div>
                            <CardTitle>Adidas</CardTitle>
                            <CardDescription>Ropa y accesorios deportivos para todos.</CardDescription>
                        </CardContainer>
                        <CardContainer>
                            <div className="card-image">
                                <img src="/img/SteamGift.png" alt="Steam" />
                            </div>
                            <CardTitle>Steam</CardTitle>
                            <CardDescription>Videojuegos y entretenimiento para todas las edades.</CardDescription>
                        </CardContainer>
                    </CardsWrapper>
                </FeaturedContainer>

                <Section>
                    <h3>¿Por qué elegir GiftWave?</h3>
                    <Description>
                        En GiftWave hacemos que regalar sea una experiencia única, rápida y segura. Estos son algunos de los beneficios que te ofrecemos para que puedas disfrutar de un proceso sin complicaciones.
                    </Description>
                    <IconContainer>
                        <div>
                            <img src="/img/facil.png" alt="Fácil" />
                            <Typography variant="h6">Fácil de Usar</Typography>
                            <Typography variant="body2">
                                Navega fácilmente por nuestras categorías y encuentra la gift card perfecta en pocos clics. Nuestro diseño intuitivo hace que todo sea simple y rápido.
                            </Typography>
                        </div>
                        <div>
                            <img src="/img/PagoSeguro.png" alt="Seguro" />
                            <Typography variant="h6">Pagos 100% Seguros</Typography>
                            <Typography variant="body2">
                                Realiza tus compras con total tranquilidad. Utilizamos tecnología avanzada de encriptación para proteger tus datos y garantizar la seguridad de todas tus transacciones.
                            </Typography>
                        </div>
                        <div>
                            <img src="/img/variedad.png" alt="Variedad" />
                            <Typography variant="h6">Amplia Variedad</Typography>
                            <Typography variant="body2">
                                Ofrecemos una enorme variedad de gift cards de tus marcas favoritas. Ya sea para ropa, videojuegos, restaurantes o actividades, tenemos lo que buscas.
                            </Typography>
                        </div>
                    </IconContainer>
                </Section>

                <TestimonialSection>
                    <h3>Testimonios de nuestros clientes</h3>
                    <TestimonialContainer>
                        <TestimonialCard>
                            <TestimonialQuote>
                                "GiftWave me facilitó encontrar el regalo perfecto para mi hermana, muy recomendable!"
                            </TestimonialQuote>
                            <RatingContainer>
                                <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarHalfIcon />
                            </RatingContainer>
                            <ClientName>- Carlos M., cliente satisfecho</ClientName>
                        </TestimonialCard>
                        <TestimonialCard>
                            <TestimonialQuote>
                                "Me encanta la variedad de tarjetas que ofrecen, siempre encuentro la ideal para cada ocasión."
                            </TestimonialQuote>
                            <RatingContainer>
                                <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon />
                            </RatingContainer>
                            <ClientName>- Laura G., compradora habitual</ClientName>
                        </TestimonialCard>
                        <TestimonialCard>
                            <TestimonialQuote>
                                "Muy fácil de usar, la experiencia fue genial y las opciones son realmente variadas."
                            </TestimonialQuote>
                            <RatingContainer>
                                <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon />
                            </RatingContainer>
                            <ClientName>- Andrés P., cliente feliz</ClientName>
                        </TestimonialCard>
                        <TestimonialCard>
                            <TestimonialQuote>
                                "GiftWave me ayudó a elegir una gift card para un compañero de trabajo, y todo fue muy rápido y seguro."
                            </TestimonialQuote>
                            <RatingContainer>
                                <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarHalfIcon />
                            </RatingContainer>
                            <ClientName>- Natalia R., usuaria nueva</ClientName>
                        </TestimonialCard>
                    </TestimonialContainer>
                </TestimonialSection>
            </HomeContainer>
        </MainLayout>
    );
};

export default Home;
