import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    RegisterContainer,
    TabsContainer,
    Tab,
    Form,
    SectionTitle,
    Button,
    LinkText,
    Row,
    Column,
    ErrorContainer,
    ProfileImagePreviewContainer,
} from './style';
import { AxiosError } from 'axios';
import MainLayout from '../Layout/MainLayout';
import TextInput from '../../Components/CustomFields/TextInput/TextInput';
import NumberInput from '../../Components/CustomFields/NumberInput/NumberInput';
import DateInput from '../../Components/CustomFields/DateInput/DateInput';
import FileInput from '../../Components/CustomFields/FileInput/FileInput';
import Cropper from 'react-easy-crop';
import EmailInput from '../../Components/CustomFields/EmailInput/EmailInput';
import PasswordInput from '../../Components/CustomFields/PasswordInput/PasswordInput';
import SelectInput from '../../Components/CustomFields/SelectInput/SelectInput';
import { validateFieldsForTab } from './Validates';
import type { FormData, Direccion, Contacto } from '../../Interfaces/formInterfaces';
import useForm from '../../Hooks/useForm';
import { useGeneros, useEstadosCiviles, usePaises, useProvincias, useLocalidades } from '../../Hooks/useConfigData';
import PhoneInputCustom from '../../Components/CustomFields/PhoneInput/PhoneInput';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import api from '../../Services/api';


const Register: React.FC = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const { formData, updateFormData } = useForm<FormData>({
        nombre: '',
        apellido: '',
        dni: '',
        cuil: '',
        fechaNacimiento: '',
        estadoCivilId: '',
        generoId: '',
        fotoPerfil: null,
        direccion: {
            calle: '',
            numero: '',
            piso: '',
            departamento: '',
            codigoPostal: '',
            localidadId: '',
            provinciaId: '',
            paisId: '',
        } as Direccion,
        contacto: {
            codigoPais: '',
            codigoArea: '',
            numero: '',
            tipo: '',
        } as Contacto,
        email: '',
        contraseña: '',
        confirmarContraseña: '',
    });
    const [fotoPreview, setFotoPreview] = useState<string | null>(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [validationMessages, setValidationMessages] = useState<string[]>([]);
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false); 

    const { generos } = useGeneros();
    const { estadosCiviles } = useEstadosCiviles();
    const { paises } = usePaises();
    const { provincias } = useProvincias(formData.direccion.paisId);
    const { localidades } = useLocalidades(formData.direccion.provinciaId);

    const handleRegister = async (event: React.FormEvent) => {
        event.preventDefault();
        const { nombre, apellido, dni, cuil, email, contraseña, direccion, contacto } = formData;
        const fields = { nombre, apellido, dni, cuil, email, contraseña, direccion, contacto };
        const errors = validateFieldsForTab(fields, activeTab);

        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            return;
        }

        setErrorMessage('Parámetros inválidos');
        setValidationMessages(['Parámetros inválidos']);
        setErrorMessage('');
        setValidationMessages([]);
        setLoading(true);

        const registerData = new FormData();
        Object.keys(formData).forEach((key) => {
            const value = formData[key as keyof FormData];
            if (typeof value === 'object' && value !== null) {
                Object.keys(value).forEach((subKey) => {
                    registerData.append(`${key}.${subKey}`, value[subKey as keyof typeof value]);
                });
            } else if (value !== null && value !== undefined) {
                registerData.append(key, value);
            }
        });

        try {
            const response = await api.post('/api/usuario', registerData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log('Registro exitoso:', response.data);
            setLoading(false);
            setErrorMessage('');
            setValidationMessages([]);
            setOpenModal(true); 
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleError = (error: any) => {
        if (error instanceof AxiosError && error.response && error.response.data) {
            const { message, validations } = error.response.data;
            if (Array.isArray(validations) && validations.length > 0) {
                setValidationMessages(validations.map((val: { Message: string }) => val.Message));
                setErrorMessage('');
            } else {
                setErrorMessage(message || 'Error en el registro');
            }
        } else {
            setErrorMessage('Error de red o de servidor');
        }
    };

    const handleNextStep = () => {
        const fields = { ...formData, contacto: { codigoPais: formData.contacto.codigoPais, codigoArea: formData.contacto.codigoArea, numero: formData.contacto.numero, tipo: formData.contacto.tipo } };
        const errors = validateFieldsForTab(fields, activeTab);
        setFieldErrors(errors);
        if (Object.keys(errors).length === 0 && activeTab < 4) {
            setActiveTab(activeTab + 1);
        }
    };

    const handleTabClick = (tab: number) => {
        if (tab <= activeTab || validateFieldsOnCurrentStep()) {
            setActiveTab(tab);
        }
    };

    const validateFieldsOnCurrentStep = () => {
        const fields = { ...formData, contacto: { codigoPais: formData.contacto.codigoPais, codigoArea: formData.contacto.codigoArea, numero: formData.contacto.numero, tipo: formData.contacto.tipo } };
        const errors = validateFieldsForTab(fields, activeTab);
        setFieldErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        updateFormData('fotoPerfil', file);
        if (file) setFotoPreview(URL.createObjectURL(file));
    };

    const onCropComplete = (croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
        console.log('Cropped Area Pixels:', croppedAreaPixels, );
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormData('confirmarContraseña', e.target.value);
        setPasswordMatchError(formData.contraseña !== e.target.value ? 'Las contraseñas no coinciden.' : '');
    };

    const handleModalClose = () => {
        setOpenModal(false);
        navigate('/login');
    };

    return (
        <MainLayout>
            <RegisterContainer>
                <ErrorContainer>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    {validationMessages.length > 0 && (
                        <ul style={{ color: 'red' }}>
                            {validationMessages.map((msg, index) => (
                                <li key={index}>{msg}</li>
                            ))}
                        </ul>
                    )}
                </ErrorContainer>
                <TabsContainer>
                    {['Datos Personales', 'Dirección', 'Contacto', 'Datos de Login'].map((title, index) => (
                        <Tab key={index} isActive={activeTab === index + 1} onClick={() => handleTabClick(index + 1)}>
                            {title}
                        </Tab>
                    ))}
                </TabsContainer>
                <Form onSubmit={handleRegister}>
                    {activeTab === 1 && (
                        <>
                            <SectionTitle>Datos Personales</SectionTitle>
                            <Row>
                                <Column>
                                    <TextInput label="Nombre" value={formData.nombre} onChange={(e) => updateFormData('nombre', e.target.value)} required error={fieldErrors['nombre']} />
                                    <NumberInput label="DNI" value={formData.dni} onChange={(e) => updateFormData('dni', e.target.value)} required error={fieldErrors['dni']} />
                                    <DateInput label="Fecha de Nacimiento" value={formData.fechaNacimiento} onChange={(e) => updateFormData('fechaNacimiento', e.target.value)} required />
                                    <SelectInput label="Estado Civil" value={formData.estadoCivilId} options={estadosCiviles.map((estado: { nombre: any; id: any }) => ({ label: estado.nombre, value: estado.id }))} onChange={(e) => updateFormData('estadoCivilId', e.target.value)} required error={fieldErrors['estadoCivilId']} />
                                </Column>
                                <Column>
                                    <TextInput label="Apellido" value={formData.apellido} onChange={(e) => updateFormData('apellido', e.target.value)} required error={fieldErrors['apellido']} />
                                    <TextInput label="CUIL" value={formData.cuil} onChange={(e) => updateFormData('cuil', e.target.value)} required error={fieldErrors['cuil']} />
                                    <SelectInput label="Género" value={formData.generoId} options={generos.map((genero: { nombre: any; id: any }) => ({ label: genero.nombre, value: genero.id }))} onChange={(e) => updateFormData('generoId', e.target.value)} required error={fieldErrors['generoId']} />
                                </Column>
                            </Row>
                            <FileInput label="Cargar Foto de Perfil" onChange={handleFileChange} customSize="250px" />
                            {fotoPreview && (
                                <ProfileImagePreviewContainer>
                                    <Cropper
                                        image={fotoPreview}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                    />
                                </ProfileImagePreviewContainer>
                            )}
                            <Button type="button" onClick={handleNextStep}>Siguiente</Button>
                        </>
                    )}
                    {activeTab === 2 && (
                        <>
                            <SectionTitle>Dirección</SectionTitle>
                            <Row>
                                <Column>
                                    <TextInput label="Calle" value={formData.direccion.calle} onChange={(e) => updateFormData('direccion.calle', e.target.value)} required error={fieldErrors['direccion.calle']} />
                                    <NumberInput label="Piso" value={formData.direccion.piso} onChange={(e) => updateFormData('direccion.piso', e.target.value)} error={fieldErrors['direccion.piso']} />
                                    <SelectInput label="País" value={formData.direccion.paisId} options={paises.map((pais: { nombre: any; id: any }) => ({ label: pais.nombre, value: pais.id }))} onChange={(e) => updateFormData('direccion.paisId', e.target.value)} required error={fieldErrors['direccion.paisId']} />
                                    <SelectInput label="Localidad" value={formData.direccion.localidadId} options={localidades.map((localidad: { nombre: any; id: any }) => ({ label: localidad.nombre, value: localidad.id }))} onChange={(e) => updateFormData('direccion.localidadId', e.target.value)} required error={fieldErrors['direccion.localidadId']} />
                                </Column>
                                <Column>
                                    <NumberInput label="Número" value={formData.direccion.numero} onChange={(e) => updateFormData('direccion.numero', e.target.value)} required error={fieldErrors['direccion.numero']} />
                                    <TextInput label="Departamento" value={formData.direccion.departamento} onChange={(e) => updateFormData('direccion.departamento', e.target.value)} />
                                    <SelectInput label="Provincia" value={formData.direccion.provinciaId} options={provincias.map((provincia: { nombre: any; id: any }) => ({ label: provincia.nombre, value: provincia.id }))} onChange={(e) => updateFormData('direccion.provinciaId', e.target.value)} required error={fieldErrors['direccion.provinciaId']} />
                                    <NumberInput label="Código Postal" value={formData.direccion.codigoPostal} onChange={(e) => updateFormData('direccion.codigoPostal', e.target.value)} required error={fieldErrors['direccion.codigoPostal']} />
                                </Column>
                            </Row>
                            <Button type="button" onClick={handleNextStep}>Siguiente</Button>
                        </>
                    )}
                    {activeTab === 3 && (
                        <>
                            <SectionTitle>Contacto</SectionTitle>
                            <Row>
                                <Column>
                                    <PhoneInputCustom
                                        label="Código País"
                                        countryValue={formData.contacto.codigoPais}
                                        onCountryChange={(value) => updateFormData('contacto.codigoPais', value)}
                                        areaCode={formData.contacto.codigoArea}
                                        onAreaCodeChange={(e) => updateFormData('contacto.codigoArea', e.target.value)}
                                        phoneNumber={formData.contacto.numero}
                                        onPhoneNumberChange={(e) => updateFormData('contacto.numero', e.target.value)}
                                        contactType={formData.contacto.tipo}
                                        onContactTypeChange={(e) => updateFormData('contacto.tipo', e.target.value)}
                                        required
                                        countryError={fieldErrors['contacto.codigoPais']}
                                        areaCodeError={fieldErrors['contacto.codigoArea']}
                                        phoneNumberError={fieldErrors['contacto.numero']}
                                        contactTypeError={fieldErrors['contacto.tipo']}
                                    />
                                </Column>
                            </Row>
                            <Button type="button" onClick={handleNextStep}>Siguiente</Button>
                        </>
                    )}
                    {activeTab === 4 && (
                        <>
                            <SectionTitle>Datos de Login</SectionTitle>
                            <EmailInput label="Correo Electrónico" value={formData.email} onChange={(e) => updateFormData('email', e.target.value)} required error={fieldErrors['email']} />
                            <PasswordInput label="Contraseña" value={formData.contraseña} onChange={(e) => updateFormData('contraseña', e.target.value)} required error={fieldErrors['contraseña']} />
                            <PasswordInput label="Confirmar Contraseña" value={formData.confirmarContraseña} onChange={handleConfirmPasswordChange} required error={passwordMatchError} />
                            <Button type="submit" disabled={loading}>
                                {loading ? 'Aguarde...' : 'Registrarse'}
                            </Button>
                        </>
                    )}
                </Form>
                <LinkText onClick={() => navigate('/login')}>¿Ya estás registrado? Iniciar sesión</LinkText>

                <Modal open={openModal} onClose={() => setOpenModal(false)}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 2,
                        }}
                    >
                        <h2>¡Registro Exitoso!</h2>
                        <p>Tu cuenta ha sido creada con éxito.</p>
                        <Button type="button" onClick={handleModalClose}>
                            Efetuar Login
                        </Button>
                    </Box>
                </Modal>

            </RegisterContainer>
        </MainLayout>
    );
};

export default Register;
