import React from 'react';
import {
    ContactContainer,
    Banner,
    Title,
    Subtitle,
    InputField,
    TextArea,
    SubmitButton,
    AttachmentInput,
    ContactInfoContainer,
    FormWrapper,
    InfoBox,
    SelectField,
} from './style';
import MainLayout from '../Layout/MainLayout';

const Contact: React.FC = () => {
    return (
        <MainLayout>
            <ContactContainer>
                {/* Banner de la página */}
                <Banner>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src="/img/giftwave_logo.png"
                            alt="GiftWave Logo"
                            style={{ width: '200px', height: 'auto', marginRight: '20px' }}
                        />
                        <div>
                            <Title>Contacto</Title>
                            <Subtitle>Nos encantaría saber de ti, ¡contáctanos!</Subtitle>
                        </div>
                    </div>
                </Banner>

                {/* Formulario de Contacto */}
                <FormWrapper>
                    <form>
                        <InputField type="text" placeholder="Nombre Completo" required />
                        <InputField type="email" placeholder="Correo Electrónico" required />
                        <InputField type="tel" placeholder="Número de Teléfono" required />
                        <SelectField required>
                            <option value="">Motivo de Contacto</option>
                            <option value="consulta">Consulta</option>
                            <option value="queja">Queja</option>
                            <option value="sugerencia">Sugerencia</option>
                            <option value="otro">Otro</option>
                        </SelectField>
                        <TextArea placeholder="Tu Mensaje Aquí" rows={5} required></TextArea>
                        <AttachmentInput type="file" />
                        <SubmitButton type="submit">Enviar</SubmitButton>
                    </form>
                </FormWrapper>
                <ContactInfoContainer>
                    <InfoBox>
                        <h3>Contacto Rápido</h3>
                        <p>Email: contacto@giftwave.com</p>
                        <p>Teléfono: +54 11 1234 5678</p>
                        <p>Dirección: Buenos Aires, Argentina</p>
                    </InfoBox>
                </ContactInfoContainer>
            </ContactContainer>
        </MainLayout>
    );
};

export default Contact;
