import styled, { keyframes } from 'styled-components';

// Animaciones previas
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Contenedor principal de GiftCards
export const GiftCardsContainer = styled.div`
  padding: 2rem;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

// Banner de la página
export const Banner = styled.div`
  background: #0099cc;
  color: white;
  padding: 3rem;
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  img {
    width: 200px;
    height: auto;
    margin-right: 20px;
    align-self: flex-start; /* Alinear logo a la izquierda */
  }

  div {
    flex-grow: 1;
    text-align: center; /* Centralizar el texto dentro del cuadro */
  }

      &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    animation: ${float} 2s ease-in-out infinite; 
  }
`;

// Estilos del título y subtítulo
export const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  color: #fff4e6;
  margin-bottom: 1rem;
`;

// Grid de GiftCards
export const GiftCardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  justify-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
`;

// Cada tarjeta de regalo
export const GiftCardItem = styled.div`
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px; // Fijamos el ancho de la tarjeta para que siempre sea el mismo
  height: 400px;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
    animation: ${float} 2s ease-in-out infinite; 

  }
`;

// Imagen de la tarjeta de regalo
export const GiftCardImage = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
`;

// Título de la tarjeta de regalo
export const GiftCardTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  margin: 1rem;
  color: #333;
`;

// Precio de la tarjeta de regalo
export const GiftCardPrice = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  color: #28a745;
  margin: 0 1rem;
`;

// Descripción de la tarjeta de regalo
export const GiftCardDescription = styled.p`
  font-size: 0.9rem;
  margin: 1rem;
  color: #555;
  overflow: hidden;
`;

// Botón de la tarjeta de regalo
export const GiftCardButton = styled.button`
  background: linear-gradient(135deg, #007bff, #00bfff);
  color: #ffffff;
  border: none;
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  margin: 1rem;
  transition: all 0.3s;

  &:hover {
    background: linear-gradient(135deg, #0056b3, #007bbf);
  }
`;

// Contenedor del filtro de categorías
export const CategoryFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const CategoryItem = styled.button<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? '#007bff' : '#f0f0f0')};
  color: ${({ selected }) => (selected ? '#fff' : '#333')};
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #007bff;
    color: #fff;
  }
`;
