import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

interface PhoneInputCustomProps {
  label: string;
  countryValue: string;
  onCountryChange: (value: string) => void;
  areaCode: string;
  onAreaCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNumber: string;
  onPhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  contactType: string;
  onContactTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  countryError?: string;
  areaCodeError?: string;
  phoneNumberError?: string;
  contactTypeError?: string;
}

const PhoneInputCustom: React.FC<PhoneInputCustomProps> = ({
  label,
  countryValue,
  onCountryChange,
  areaCode,
  onAreaCodeChange,
  phoneNumber,
  onPhoneNumberChange,
  contactType,
  onContactTypeChange,
  required = false,
  countryError = '',
  areaCodeError = '',
  phoneNumberError = '',
  contactTypeError = '',
}) => {
  return (
    <div style={{ width: '100%' }}>
      <label style={{ display: 'block', marginBottom: '8px' }}>
        {label} {required && '*'}
      </label>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PhoneInput
            country={'us'}
            value={countryValue}
            onChange={onCountryChange}
            inputProps={{
              name: 'codigoPais',
              required: required,
              autoFocus: false,
            }}
            placeholder=""
            containerStyle={{ width: '100%' }}
            inputStyle={{
              width: '100%',
              borderColor: countryError ? 'red' : undefined,
              height: '56px',
              borderRadius: '4px',
            }}
          />
          {countryError && <span style={{ color: 'red', fontSize: '12px' }}>{countryError}</span>}
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Código Área"
            value={areaCode}
            onChange={onAreaCodeChange}
            required={required}
            variant="outlined"
            fullWidth
            error={Boolean(areaCodeError)}
            helperText={areaCodeError}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Número de Teléfono"
            value={phoneNumber}
            onChange={onPhoneNumberChange}
            required={required}
            variant="outlined"
            fullWidth
            error={Boolean(phoneNumberError)}
            helperText={phoneNumberError}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Tipo de Contacto"
            value={contactType}
            onChange={onContactTypeChange}
            required={required}
            variant="outlined"
            fullWidth
            error={Boolean(contactTypeError)}
            helperText={contactTypeError}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PhoneInputCustom;
