import React from 'react';
import { StyledButton } from './style';

interface FileInputProps {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customSize?: string; 
}

const FileInput: React.FC<FileInputProps> = ({ label, onChange, customSize = '150px' }) => {
  return (
    <StyledButton
      variant="contained"
      customSize={customSize}
      component="label"
    >
      {label}
      <input
        type="file"
        hidden
        onChange={onChange}
      />
    </StyledButton>
  );
};

export default FileInput;
